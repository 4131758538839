import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { assignmentGroupOptions, problemAreaOptions } from '../../utils/constants';
import { PriorityOptions } from './PriorityOptions/index';
import { FormSchema } from '../../utils/schema';
import { ErrorMessage } from './ErrorMessage';
import { FileUploader } from './File/FileUploader';
import { IRawFormValues } from '../../interfaces/form';
import { styles } from './styles';
import { useMakeRequests } from './useMakeRequests';
import { useWatsonQSParser } from './useWatsonQSParser';
import { WatsonInfo } from './WatsonInfo';

export const Form: FC = () => {
  const { submitIssue, isSubmitted, isLoading } = useMakeRequests();
  // custom hook is needed here to have responsive Radio options
  const radioSize = useBreakpointValue(styles.radio.size);
  const { handleSubmit, register, watch, errors, setValue, control } = useForm<IRawFormValues>({
    resolver: yupResolver(FormSchema),
    mode: 'onTouched',
  });

  // grabbing the initial query strings from URL and pre-populating a few fields
  const qsParams = useWatsonQSParser();
  useEffect(() => {
    if (qsParams?.url) setValue('url', qsParams.url);
    if (qsParams?.DFPCREATIVEID) setValue('creativeOrLineInfo', qsParams.DFPCREATIVEID);
    else if (qsParams?.DFPLINEID) setValue('creativeOrLineInfo', qsParams?.DFPLINEID);
  }, [qsParams]);

  // this isn't great for performance because of the re-renders it'll cause,
  // but this state var for the compression is needed at this level so
  // that the form cannot be submitted while an uploaded image is still compressing
  const [isCompressing, setIsCompressing] = useState<boolean>(false);
  const isSubmitBtnDisabled = isCompressing || isSubmitted;

  const currentProblemAreaValue = watch('problemArea');
  const currentPriorityValue = watch('priority');
  const currentFileValue = watch('file');

  return (
    <Box pt="4rem" m="0 auto" maxW="50rem" p="2rem">
      {!!Object.keys(qsParams).length && <WatsonInfo qsParams={qsParams} />}
      <form onSubmit={handleSubmit((values) => submitIssue(values, qsParams))}>
        <Stack spacing="1rem">
          <FormControl isRequired fontSize=".5em">
            <FormLabel htmlFor="reporter" {...styles.label}>
              Reporter
            </FormLabel>
            <Input
              type="email"
              name="reporter"
              placeholder="jdoe@redventures.com"
              ref={register({ required: true })}
              {...styles.input}
            />
            <FormHelperText {...styles.helperText}>Enter your RV email address.</FormHelperText>
          </FormControl>
          <ErrorMessage errors={errors} name="reporter" />

          <FormControl isRequired>
            <FormLabel htmlFor="url" {...styles.label}>
              URL{' '}
              <FormHelperText as="span" fontSize=".85rem">
                or app where the problem is occurring
              </FormHelperText>
            </FormLabel>
            <Input
              name="url"
              placeholder="https://www.cnet.com"
              ref={register({ required: true })}
              {...styles.input}
            />
          </FormControl>
          <ErrorMessage errors={errors} name="url" />

          <FormControl isRequired>
            <FormLabel htmlFor="subject" {...styles.label}>
              Subject
            </FormLabel>
            <Input name="subject" ref={register} {...styles.input} />
          </FormControl>
          <ErrorMessage errors={errors} name="subject" />

          <FormControl isRequired>
            <FormLabel htmlFor="description" {...styles.label}>
              Describe the problem
            </FormLabel>
            <Textarea name="description" ref={register} size="lg" {...styles.input} />
            <FormHelperText {...styles.helperText}>
              Include browser and operating system versions when possible. Provide order/line
              information if known.
            </FormHelperText>
          </FormControl>
          <ErrorMessage errors={errors} name="description" />

          <FormControl isRequired>
            <FormLabel htmlFor="problemArea" {...styles.label}>
              Problem area
            </FormLabel>
            <RadioGroup name="problemArea">
              <Stack spacing=".5rem" direction="column">
                {problemAreaOptions.map((pa) => (
                  <Radio key={pa} value={pa} ref={register} size={radioSize}>
                    {pa}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </FormControl>
          <ErrorMessage errors={errors} name="problemArea" />

          {currentProblemAreaValue === 'Other' && (
            <Box pl=".75rem">
              <FormControl mt=".5rem !important" isRequired>
                <Input type="text" name="problemAreaOther" ref={register} {...styles.input} />
              </FormControl>
              <ErrorMessage errors={errors} name="problemAreaOther" />
            </Box>
          )}

          <FormControl isRequired>
            <FormLabel htmlFor="assignmentGroup" {...styles.label}>
              Assignment group
            </FormLabel>
            <RadioGroup name="assignmentGroup">
              <Stack spacing=".5rem" direction="column">
                {assignmentGroupOptions.map((ag) => (
                  <Radio key={ag} value={ag} ref={register} size={radioSize}>
                    {ag}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </FormControl>
          <ErrorMessage errors={errors} name="assignmentGroup" />

          <PriorityOptions
            register={register}
            errors={errors}
            currentPriorityValue={currentPriorityValue}
          />

          <FormControl>
            <FormLabel htmlFor="creativeOrLineInfo" {...styles.label}>
              Creative/Line Information
            </FormLabel>
            <Input name="creativeOrLineInfo" ref={register} {...styles.input} />
            <FormHelperText>
              Input any Creative or Line Ids related to this disruption.
            </FormHelperText>
          </FormControl>

          <FileUploader
            control={control}
            currentFileValue={currentFileValue}
            setIsCompressing={setIsCompressing}
          />

          <FormControl>
            <FormLabel htmlFor="ccEmails" {...styles.label}>
              CC emails
            </FormLabel>
            <Textarea
              name="ccEmails"
              ref={register}
              size="sm"
              placeholder="jdoe@redventures.com, jsmith@redventures.com"
              {...styles.input}
            />
            <FormHelperText>Comma separate each email address.</FormHelperText>
          </FormControl>
          <ErrorMessage errors={errors} name="ccEmails" />

          <Button
            type="submit"
            my="2.5rem !important"
            backgroundColor="teal"
            color="white"
            isLoading={isLoading}
            isDisabled={isSubmitBtnDisabled}
            {...styles.button}
          >
            {isSubmitted ? 'Submitted' : 'Submit'}
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
