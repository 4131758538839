import React, { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';

type DetailProps = {
  title: string;
  contents: string;
};

export const Detail: FC<DetailProps> = (props: DetailProps) => {
  return (
    <Box m={5}>
      <b>{props.title}</b>
      <Text ml={5}>{props.contents}</Text>
    </Box>
  );
};
