import * as yup from 'yup';

export const FormSchema = yup.object().shape({
  reporter: yup
    .string()
    .trim()
    .email('Reporter must be a valid email.')
    .required('Reporter is a required field.'),
  url: yup.string().trim().required('URL is a required field.'),
  subject: yup.string().trim().required('Subject is a required field.'),
  description: yup
    .string()
    .trim()
    .max(2048, 'Description can only have 2048 characters.')
    .required('Description is a required field.'),
  problemArea: yup
    .string()
    .required()
    .typeError(
      "A problem area is required. If none of these options apply, please select 'Other' and add one."
    ),
  // only validates this field when 'Other' is selected as a `problemArea`
  problemAreaOther: yup.string().when('problemArea', {
    is: 'Other',
    then: yup
      .string()
      .trim()
      .required('Please either enter a custom problem area or select one of the options above.'),
  }),
  assignmentGroup: yup.string().required().typeError('An assignment group is required.'),
  priority: yup.string().required().typeError('A priority is required.'),
  // only validates this field when 'Live Emergency (Page Someone)' is selected as a `priority`
  phoneNumber: yup.string().when('priority', {
    is: 'Live Emergency (Page Someone)',
    then: yup
      .string()
      .trim()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(7, 'Phone number must be at least 7 characters.')
      .max(15, 'Phone number must be at most 15 characters.')
      .required('Please enter a valid phone number.'),
  }),
  creativeOrLineInfo: yup.string(),
  ccEmails: yup
    .string()
    .test(
      'comma-separated-email-validation',
      'All emails must be valid. Please check the comma separated list and try again.',
      (value) => {
        const emails = value?.split(',');
        const isValid = emails?.every((email) => {
          return yup.string().email().isValidSync(email.trim());
        });

        return isValid ?? false;
      }
    ),
});
