import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IWatsonQSValues, WatsonQSValuesIndexer } from '../../interfaces/form';

export const useWatsonQSParser = (): IWatsonQSValues => {
  const [qsParams, setQsParams] = useState<IWatsonQSValues>({});

  const location = useLocation();
  const qp = new URLSearchParams(location.search);

  // filtering out the expected query strings coming from Watson
  const filterExpectedKeys = () => {
    const expectedKeys = [
      'REDIRECT',
      'PLACEHOLDER',
      'DFPADVERTISERID',
      'DFPLINEID',
      'DFPCREATIVEID',
      'url',
      'REFURL',
      'UA',
    ];
    const final: WatsonQSValuesIndexer = {};

    if (qp.toString().length) {
      expectedKeys.forEach((key) => {
        const expectedQueryString = qp.get(key);
        if (!expectedQueryString) return;

        final[key] = expectedQueryString;
      });
    }

    return final;
  };

  useEffect(() => {
    const filteredParams = filterExpectedKeys();
    setQsParams(filteredParams);
  }, []);

  return qsParams;
};
