import React, { FC } from 'react';
import { BrowserRouter as Router, Switch, Route, Link as ReactRouterLink } from 'react-router-dom';
import { Box, Flex, Heading, Icon, Link } from '@chakra-ui/react';
import { Form } from './components/Form/index';
import { AiOutlineForm } from 'react-icons/ai';
import { ColorModeSwitcher } from './components/ColorModeSwitcher';
import { Success, SuccessProps } from './components/Success/Success';

export const App: FC = () => {
  return (
    <Router>
      <Box>
        <Flex>
          <Link as={ReactRouterLink} to="/" display="flex" alignItems="center">
            <Icon as={AiOutlineForm} fontSize={{ base: '1.25rem', md: '1.75rem' }} ml="1rem" />
            <Heading ml=".5rem" as="h1" fontSize={{ base: '1.5rem', md: '2rem' }}>
              Ad Disruption Form
            </Heading>
          </Link>
          <ColorModeSwitcher fontSize={{ base: '1.25rem', md: '1.75rem' }} />
        </Flex>
        <Switch>
          <Route path="/" exact>
            <Form />
          </Route>
          <Route
            path="/success"
            render={({ location }) => <Success {...(location.state as SuccessProps)} />}
          />
        </Switch>
      </Box>
    </Router>
  );
};
