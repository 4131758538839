export const styles = {
  label: {
    fontSize: { base: '.875rem', md: '1rem' },
  },
  input: {
    fontSize: { base: '.875rem', md: '1rem' },
    borderWidth: '2px',
    borderColor: 'gray.300',
  },
  helperText: {
    fontSize: { base: '.75rem', md: '0.875rem' },
  },
  radio: {
    size: { base: 'sm', md: 'md' },
  },
  button: {
    fontSize: { base: '.875rem', md: '1rem' },
  },
  popover: {
    fontSize: { base: '.75rem', md: '0.875rem' },
  },
};
