import { Popover, PopoverTrigger, Box, Icon, PopoverContent, PopoverBody } from '@chakra-ui/react';
import React, { FC } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { styles } from '../styles';

interface IProps {
  description: string;
}

export const PriorityPopover: FC<IProps> = ({ description }: IProps) => (
  <Popover placement="bottom" trigger="hover" closeDelay={300}>
    <PopoverTrigger>
      <Box as="span" ml=".5rem">
        <Icon as={BsFillInfoCircleFill} />
      </Box>
    </PopoverTrigger>

    <PopoverContent>
      <PopoverBody {...styles.popover}>{description}</PopoverBody>
    </PopoverContent>
  </Popover>
);
