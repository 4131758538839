import React, { FC } from 'react';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  RadioGroup,
  Stack,
  Radio,
  Input,
  Box,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { UseFormMethods } from 'react-hook-form';
import { priorityOptions } from '../../../utils/constants';
import { ErrorMessage, Props as ErrorMessageProps } from '.././ErrorMessage';
import { styles } from '.././styles';
import { IRawFormValues } from '../../../interfaces/form';
import { isOfficeHours } from '../../../utils/officeHours';
import { PriorityPopover } from './PriorityPopover';

interface IProps {
  register: UseFormMethods['register'];
  errors: ErrorMessageProps['errors'];
  currentPriorityValue: IRawFormValues['priority'];
}

export const PriorityOptions: FC<IProps> = (props: IProps) => {
  const { register, currentPriorityValue, errors } = props;

  // custom hook is needed here to have responsive Radio options
  const radioSize = useBreakpointValue(styles.radio.size);

  const officeHours = isOfficeHours();

  return (
    <>
      <FormControl isRequired>
        <FormLabel htmlFor="priority" {...styles.label}>
          Priority
        </FormLabel>
        <FormHelperText>
          Normal Business Hours are 9:00am-9:00pm EST (6:00am-6:00pm PST) M-F.
        </FormHelperText>
        <FormHelperText>
          It is currently <Text as="b">{officeHours ? 'ON' : 'OFF'}</Text> HOURS.
        </FormHelperText>
        <RadioGroup name="priority" mt=".5rem">
          <Stack spacing=".5rem" direction="column">
            {priorityOptions.map((po) => (
              <Radio key={po.name} value={po.name} ref={register} size={radioSize}>
                {po.name}
                <PriorityPopover description={po.description} />
              </Radio>
            ))}
            {!officeHours && (
              <Box color="red.400">
                <Radio value="Live Emergency (Page Someone)" ref={register} size={radioSize}>
                  Live Emergency (Page Someone)
                  <PriorityPopover
                    description={
                      'Greater than $10k revenue loss per day, network outage, front door or highly important page down/not working. Will page Ad Ops and other resources. You (or someone) must be available by phone during a live site emergency.'
                    }
                  />
                </Radio>
                {currentPriorityValue === 'Live Emergency (Page Someone)' && (
                  <Box pl=".75rem">
                    <FormControl isRequired>
                      <FormLabel htmlFor="phoneNumber" {...styles.label}>
                        Phone Number
                      </FormLabel>
                      <Input
                        name="phoneNumber"
                        ref={register({
                          valueAsNumber: true,
                        })}
                        placeholder="2136742904"
                        {...styles.input}
                      />
                      <FormHelperText fontSize=".85em">
                        Please enter your phone number so that a team member will be able to contact
                        you for additional information.
                      </FormHelperText>
                    </FormControl>
                    <ErrorMessage errors={errors} name="phoneNumber" />
                  </Box>
                )}
              </Box>
            )}
          </Stack>
        </RadioGroup>
      </FormControl>
      <ErrorMessage errors={errors} name="priority" />
    </>
  );
};
