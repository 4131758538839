import React from 'react';
import ReactDOM from 'react-dom';
import { ColorModeScript } from '@chakra-ui/react';
import { App } from './App';
import { ThemeProvider } from './components/ThemeProvider';

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
