import { Box, Flex, Icon, Progress, Text } from '@chakra-ui/react';
import prettyBytes from 'pretty-bytes';
import React, { FC } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';

interface IProps {
  uncompressedFile: File;
  compressedFile: File | undefined;
  compressionProgress: number | undefined;
}

export const FileLabel: FC<IProps> = ({
  uncompressedFile,
  compressedFile,
  compressionProgress,
}: IProps) => {
  const { name, size } = uncompressedFile;
  const fileSize = prettyBytes(size);

  return (
    <>
      <Flex alignItems="center" wrap="wrap">
        <Text>
          {name} -{' '}
          <Box as="span" fontWeight="bold">
            {fileSize}
          </Box>
        </Text>
        <Icon as={AiFillCheckCircle} ml=".25rem" color="green" />
        {compressedFile && compressionProgress === 100 && (
          <Text ml=".25rem" fontStyle="italic" fontSize=".85rem">
            (compressed down to{' '}
            <Box as="span" fontWeight="bold">
              {prettyBytes(compressedFile.size)}
            </Box>
            )
          </Text>
        )}
      </Flex>
      {compressionProgress && compressionProgress !== 100 && (
        <Box>
          <Text ml=".25rem" fontStyle="italic" fontSize=".85rem" textAlign="center">
            (compressing... {compressionProgress}%)
          </Text>
          <Progress
            mt=".25rem"
            isAnimated
            hasStripe
            colorScheme="blue"
            value={compressionProgress}
          />
        </Box>
      )}
    </>
  );
};
