import React, { FC } from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import { styles } from './styles';
import { Detail } from './detail';
import { IWatsonQSValues } from '../../interfaces/form';

export type SuccessProps = {
  ticketLink?: string;
  summary?: string;
  reporter?: string;
  url?: string;
  priority?: string;
  problemArea?: string;
  assignmentGroup?: string;
  comments?: string;
  watsonAdData?: IWatsonQSValues;
  ccList?: {
    watchers?: string[];
    notWatching?: string[];
    unknownWatchers?: string[];
  };
};

export const Success: FC<SuccessProps> = (props: SuccessProps) => (
  <Box {...styles.topLevel}>
    <Text mb={5} {...styles.header}>
      SUCCESS! DISRUPTION REPORTED
    </Text>
    <Box {...styles.link}>
      <Text as="b">Link to Submitted Issue:</Text>
      <Text ml={5}>
        <Link href={props.ticketLink} target="_blank" rel="noopener noreferrer">
          {props.ticketLink}
        </Link>
      </Text>
    </Box>
    <Box {...styles.summary}>
      <Text as="b">Summary:</Text>
      <Text ml={5}>{props.summary}</Text>
    </Box>
    <Box>
      <Text as="b">Details: </Text>
      <Box shadow="md" borderWidth="1px" borderRadius="md" mr={'5%'} my={4}>
        <Detail title="REPORTED BY:" contents={props.reporter || ''} />
        <Detail title="URL:" contents={props.url || ''} />
        <Detail title="PRIORITY:" contents={props.priority || ''} />
        <Detail title="PROBLEM AREA:" contents={props.problemArea || ''} />
        <Detail title="ASSIGNMENT GROUP:" contents={props.assignmentGroup || ''} />
        <Detail title="COMMENTS:" contents={props.comments || ''} />
        <Detail title="CC LIST:" contents={props.ccList?.watchers?.join(', ') || ''} />
        {!!props.ccList?.notWatching?.length && (
          <Detail
            title="CC LIST (Not Watching):"
            contents={props.ccList?.notWatching?.join(', ') || ''}
          />
        )}
        {!!props.ccList?.unknownWatchers?.length && (
          <Detail
            title="CC LIST (Unknown Users):"
            contents={props.ccList?.unknownWatchers?.join(', ') || ''}
          />
        )}
        {props.watsonAdData && !!Object.keys(props.watsonAdData).length && (
          <Box ml={5} mb={5}>
            <Text as="b">AD DATA PASSED BY WATSON:</Text>
            <Box ml={5}>
              <Text>
                <Text as="b">REDIRECT:</Text>: {props.watsonAdData?.REDIRECT}
              </Text>
              <Text>
                <Text as="b">PLACEHOLDER:</Text> {props.watsonAdData?.PLACEHOLDER}
              </Text>
              <Text>
                <Text as="b">DFPADVERTISERID:</Text> {props.watsonAdData?.DFPADVERTISERID}
              </Text>
              <Text>
                <Text as="b">DFPLINEID:</Text> {props.watsonAdData?.DFPLINEID}
              </Text>
              <Text>
                <Text as="b">DFPCREATIVEID:</Text> {props.watsonAdData?.DFPCREATIVEID}
              </Text>
              <Text>
                <Text as="b">url:</Text> {props.watsonAdData?.url}
              </Text>
              <Text>
                <Text as="b">REFURL:</Text> {props.watsonAdData?.REFURL}
              </Text>
              <Text>
                <Text as="b">UA:</Text> {props.watsonAdData?.UA}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  </Box>
);
