import { IPriority, IRawFormValues } from '../interfaces/form';

export const problemAreaOptions: IRawFormValues['problemArea'][] = [
  'Sponsorship campaign/creative not running correctly',
  'Broken Creative (misaligned, not clicking through, breaking site functionality, non-secure, etc)',
  'Malicious/Offensive/Inappropriate Creative',
  'No ads are serving on entire site/app (Ad Outage)',
  'Campaign scheduling issue (wrong tags, wrong click through, wrong creative, etc)',
  'Emergency campaign pause request',
  'Other',
];

export const assignmentGroupOptions: IRawFormValues['assignmentGroup'][] = [
  'Exchange',
  'Direct Sold',
  'Unknown',
];

export const priorityOptions: IPriority[] = [
  {
    name: 'Major',
    description:
      'We respond to these in the order we receive them. Average response time is about 1 hour during normal business hours.',
  },
  {
    name: 'Critical',
    description:
      'Will jump to the top of the queue. Average response time is about 20 minutes during normal business hours.',
  },
];

export const supportContactEmail = 'ad-disruption@redventures.com';
