import React, { FC } from 'react';
import {
  Box,
  Button,
  Code,
  Icon,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Text,
  Table,
  TableCaption,
  Thead,
  Flex,
} from '@chakra-ui/react';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { IWatsonQSValues } from '../../interfaces/form';
import { styles } from './styles';

interface IProps {
  qsParams: IWatsonQSValues;
}

export const WatsonInfo: FC<IProps> = (props) => {
  const { qsParams } = props;

  return (
    <Popover placement="bottom" defaultIsOpen trigger="hover">
      <PopoverTrigger>
        <Button
          leftIcon={<Icon as={BsFillInfoCircleFill} />}
          colorScheme="teal"
          variant="outline"
          mb="1rem"
          {...styles.button}
        >
          Info from Watson
        </Button>
      </PopoverTrigger>

      <PopoverContent backgroundColor="gray.700" color="white">
        <PopoverHeader>Information provided by Watson</PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody {...styles.popover}>
          {Object.entries(qsParams).map(([key, value]) => {
            return (
              <Flex key={key}>
                <Text as="b">{key}</Text>:{' '}
                <Text ml=".25rem" fontSize=".85rem">
                  {value}
                </Text>
              </Flex>
            );
          })}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
