export const styles = {
  header: {
    fontWeight: 'bold',
  },
  topLevel: {
    marginLeft: '7%',
    marginTop: '25px',
  },
  link: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  summary: {
    marginTop: '10px',
    marginBottom: '10px',
  },
};
