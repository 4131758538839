import { Text } from '@chakra-ui/react';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import React, { FC } from 'react';

export type Props = React.ComponentProps<typeof HookFormErrorMessage>;

export const ErrorMessage: FC<Props> = ({ errors, name }) => (
  <HookFormErrorMessage
    errors={errors}
    name={name}
    as={<Text mt=".35rem !important" color="red" fontSize={{ base: '.75rem', md: '.875rem' }} />}
  />
);
