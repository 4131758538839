import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IRawFormValues, IWatsonQSValues } from '../../interfaces/form';
import { getAPIUrl } from '../../utils/config';
import { supportContactEmail } from '../../utils/constants';
import { prepRawFormValues } from '../../utils/prepRawFormValues';
import { SuccessProps } from '../Success/Success';

interface IUseMakeRequestsReturn {
  isSubmitted: boolean;
  isLoading: boolean;
  submitIssue: (values: IRawFormValues, qsParams: IWatsonQSValues) => void;
}

interface IResponse {
  link: string;
  issueKey: string;
  values: {
    reporter: string;
    url: string;
    subject: string;
    description: string;
    problemArea: string;
    assignmentGroup: string;
    priority: string;
    watsonData: IWatsonQSValues;
  };
  watchers: {
    watching: {
      email: string;
    }[];
    notWatching: {
      email: string;
    }[];
    unknownUsers: {
      email: string;
    }[];
  };
}

export const useMakeRequests = (): IUseMakeRequestsReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const toast = useToast();
  const history = useHistory(); // declare here, inside a React component.

  const submitIssue = async (values: IRawFormValues, qsParams: IWatsonQSValues) => {
    setIsLoading(true);
    const finalValues = prepRawFormValues(values);

    // grabbing everything but `file` because it has
    // to be sent in separate request
    const { file, ...rest } = finalValues;
    const ax = axios.create({ baseURL: getAPIUrl() });
    const body = { ...rest, watsonData: { ...qsParams } };

    let displayResponse: SuccessProps = {};
    let issueResponse: IResponse;
    try {
      const { data } = await ax.post<IResponse>('/issue', body);
      issueResponse = data;
    } catch (error) {
      setIsLoading(false);
      toast({
        title: `Error occurred while submitting the form.`,
        description: `Please reach out to ${supportContactEmail} directly to report an ad disruption.`,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'bottom-left',
      });
      return;
    }

    // separate try/catch block here because this request is optional
    // and the response shouldn't stop the rest
    try {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('issueKey', issueResponse.issueKey);

        await ax.post('/upload', formData);
      }
    } catch (error) {
      const errMsg = error?.response?.data?.error as string;
      const descriptionBase = 'Form was submitted successfully, but file upload was unsuccessful';
      const description = errMsg.length ? `${descriptionBase}: ${errMsg}` : descriptionBase;

      toast({
        title: 'Error occurred while uploading file.',
        description,
        status: 'warning',
        duration: 9000,
        isClosable: true,
        position: 'bottom-left',
      });
    }

    toast({
      title: 'Form submitted successfully!',
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'bottom-left',
    });

    const ccList = {
      watchers: issueResponse.watchers.watching.map((u) => u.email),
      notWatching: issueResponse.watchers.notWatching.map((u) => u.email),
      unknownWatchers: issueResponse.watchers.unknownUsers.map((u) => u.email),
    };

    displayResponse = {
      ticketLink: issueResponse.link,
      summary: issueResponse.values.subject,
      reporter: issueResponse.values.reporter,
      url: issueResponse.values.url,
      priority: issueResponse.values.priority,
      problemArea: issueResponse.values.problemArea,
      assignmentGroup: issueResponse.values.assignmentGroup,
      comments: issueResponse.values.description,
      watsonAdData: issueResponse.values.watsonData,
      ccList,
    };

    setIsLoading(false);
    setIsSubmitted(true);
    history.push('/success', { ...displayResponse });
  };

  return { submitIssue, isSubmitted, isLoading };
};
