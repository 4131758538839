import { Interval } from 'luxon';
// no types available so everything has to be typed with 'any'
import { DateTime } from 'luxon-business-days';

// returns a boolean based on if it's during business hours
export const isOfficeHours = (): boolean => {
  const dtUTC = DateTime.utc();

  // Monday - Friday
  const BUSINESS_DAYS = [1, 2, 3, 4, 5];
  const BUSINESS_HOLIDAYS = getHolidays(dtUTC);
  dtUTC.setupBusiness({ businessDays: BUSINESS_DAYS, holidayMatchers: BUSINESS_HOLIDAYS });

  // TODO: remove these logs after testing in dev is done
  if (!dtUTC.isBusinessDay()) {
    console.log('off hours! it is not a business day');
    return false;
  }

  if (dtUTC.isHoliday()) {
    console.log('off hours! it is a holiday');
    return false;
  }

  if (!isDuringOfficeHours(dtUTC)) {
    console.log('off hours! it is not during regular office hours');
    return false;
  }

  return true;
};

const isDuringOfficeHours = (dtUTC: any): boolean => {
  // the team's office hours are 9AM - 9PM EST so
  // logic will operate in that time zone
  const TIMEZONE = 'America/New_York';
  const dtEST = dtUTC.setZone(TIMEZONE);

  const startTimeEST = DateTime.fromObject({ hour: 9, zone: TIMEZONE });
  const endTimeEST = DateTime.fromObject({ hour: 21, zone: TIMEZONE });

  return Interval.fromDateTimes(startTimeEST, endTimeEST).contains(dtEST);
};

const getHolidays = (dtUTC: any) => {
  const {
    isNewYearsDay,
    isMLKDay,
    isMemorialDay,
    isIndependanceDay,
    isLaborDay,
    isThanksgivingDay,
    isChristmasDay,
  } = dtUTC.availableHolidayMatchers;

  // using 2021 RV US Corporate Holidays located in Arvy:
  // https://arvy.io/news/2021-holiday-schedules#uscorp
  return [
    isNewYearsDay,
    isMLKDay,
    isMemorialDay,
    isIndependanceDay,
    isLaborDay,
    isThanksgivingDay,
    isDayAfterThanksgiving,
    isChristmasDay,
    isWinterBreak,
    isRechargeDay,
  ];
};

// custom holiday matchers
const isWinterBreak = (inst: any) => {
  const monthMatch = inst.month === 12;
  const days = [24, 26, 27, 28, 29, 30, 31];
  const daysMatch = days.some((d) => d === inst.day);

  return monthMatch && daysMatch;
};

const isDayAfterThanksgiving = (inst: any) => {
  const monthMatch = inst.month === 11;
  /*
    it's not clear how to just add one day to the isThanksgiving
    holiday matcher, so we have to do it manually for now
    this might have to be updated in the future since Thanksgiving
    is celebrated on the 4th Thursday of Nov.
  */
  const dayMatch = inst.day === 26;

  return monthMatch && dayMatch;
};

// these are tricky to plan for because they're announced randomly,
// so this list might have to be checked & updated often
const isRechargeDay = (inst: any) => {
  // mm/dd format
  const dates = [
    [2, 15], // February Recharge Day
    [3, 26], // March Recharge Day
  ];

  return dates.some(([rMonth, rDay]) => {
    if (rMonth === inst.month && rDay === inst.day) return true;
  });
};
