import { FC, ReactNode } from 'react';
import { extendTheme, ChakraProvider } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

interface IProps {
  children: ReactNode;
}

export const ThemeProvider: FC<IProps> = (props: IProps) => {
  const { children } = props;

  const theme = extendTheme({
    styles: {
      global: (props) => ({
        body: {
          backgroundColor: mode('#f8f8ff', 'gray.900')(props),
        },
      }),
    },
  });

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
