import { IRawFormValues, FinalFormValues } from '../interfaces/form';

export const prepRawFormValues = (values: IRawFormValues): FinalFormValues => {
  //  excluding the first few keys out and then grabbing the rest
  const { problemAreaOther, phoneNumber, ccEmails, creativeOrLineInfo, ...rest } = values;
  const final: FinalFormValues = { ...rest };

  if (problemAreaOther) final.problemArea += ` - ${problemAreaOther}`;
  if (phoneNumber) final.phoneNumber = parseInt(phoneNumber);

  if (ccEmails.trim()) {
    final.ccEmails = ccEmails.split(',').map((e) => e.trim());
  }
  if (creativeOrLineInfo.trim()) final.creativeOrLineInfo = creativeOrLineInfo;

  return final;
};
