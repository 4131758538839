import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import imageCompression from 'browser-image-compression';
import prettyBytes from 'pretty-bytes';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { styles } from '../styles';
import { FileLabel } from './FileLabel';

interface IProps {
  currentFileValue: File | undefined;
  control: Control;
  setIsCompressing: Dispatch<SetStateAction<boolean>>;
}

export const FileUploader: FC<IProps> = (props: IProps) => {
  const [uncompressedFile, setUncompressedFile] = useState<File | null>();
  const [compressionProgress, setCompressionProgress] = useState<number>();
  const toast = useToast();

  const { control, currentFileValue, setIsCompressing } = props;

  /*
    15 MB
    the max size we're letting the compression lib handle
  */
  const MAX_UNCOMPRESSED_FILE_SIZE = 15000000;
  /*
    5 MB
    the max size the API can handle. also the target compression size
  */
  const MAX_FILE_SIZE = 5000000;

  /*
    we're manually handing the onChange process for file uploads because
    we need to compress the file to a size under 6 MB so that the API will accept it
  */
  const handleFileChange = async (
    files: FileList | null,
    cb: (value: File | null) => void
  ): Promise<void> => {
    if (!files?.length) return;

    const file = files[0];
    if (file.size > MAX_UNCOMPRESSED_FILE_SIZE) {
      toast({
        title: 'File too large!',
        description: `The file size of ${file.name} was ${prettyBytes(
          file.size
        )}. Please keep image uploads under ${prettyBytes(MAX_UNCOMPRESSED_FILE_SIZE)}.`,
        status: 'warning',
        duration: 9000,
        isClosable: true,
        position: 'bottom-left',
      });

      setUncompressedFile(null);
      // resetting the file value to let the user try again
      return cb(null);
    }

    try {
      setIsCompressing(true);
      /*
        setting the current file's value in a separate variable
        so that the size before compression can be tracked
      */
      setUncompressedFile(file);

      const options = {
        maxSizeMB: MAX_FILE_SIZE / 1000 / 1000,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        onProgress: setCompressionProgress,
      };
      const newFile = await imageCompression(file, options);
      setIsCompressing(false);

      /*
        although the type says `File`, the image compressor returns
        a Blob - so we have to manually convert it to a File
      */
      return cb(new File([newFile], newFile.name, { type: newFile.type }));
    } catch (error) {
      setIsCompressing(false);

      if (file.size > MAX_FILE_SIZE) {
        /*
          if the compression was unsuccessful AND their file is over
          the max size the API can support, just tell them to try
          another tool and reset the `file` value to null
        */
        toast({
          title: 'File too large!',
          description: `The file size of ${file.name} was ${prettyBytes(
            file.size
          )} and we're unable to compress it. Please compress the image in another tool(ex: https://imagecompressor.com) first and try again.`,
          status: 'warning',
          duration: 9000,
          isClosable: true,
          position: 'bottom-left',
        });

        setUncompressedFile(null);
        // if the file was already temporarily set, just set it back to null
        return cb(null);
      }

      setUncompressedFile(file);
      return cb(file);
    }
  };

  return (
    <FormControl>
      <FormLabel htmlFor="file" {...styles.label}>
        File
      </FormLabel>
      <Box
        // need to set hover here for button because _hover on Button
        // is overriding the other styles right now
        _groupHover={{ cursor: 'pointer' }}
      >
        <Button as={FormLabel} colorScheme="blue" {...styles.button}>
          Upload a screenshot
        </Button>
      </Box>
      <Controller
        control={control}
        name="file"
        defaultValue={null}
        render={({ onChange }) => {
          return (
            <Input
              type="file"
              name="file"
              accept="image/*"
              onChange={(e) => {
                // passing the onChange callback in directly so that the
                // UI doesn't get hung by waiting for the compression to finish
                handleFileChange(e.target.files, onChange);
              }}
              // hiding the default "Browse..." element since it's difficult to style
              width="0.1px"
              height="0.1px"
              opacity="0"
              overflow="hidden"
              position="absolute"
              z-index="-1"
              {...styles.input}
            />
          );
        }}
      />

      {uncompressedFile && (
        <FileLabel
          uncompressedFile={uncompressedFile}
          compressedFile={currentFileValue}
          compressionProgress={compressionProgress}
        />
      )}

      <FormHelperText {...styles.helperText}>
        Please attach a screenshot of the issue.
      </FormHelperText>
    </FormControl>
  );
};
